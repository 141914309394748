<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column justify-content-center">
          <h1>{{ $t('orders.cart') }}</h1>
          <p class="supplier-name">{{ supplierName }}</p>
        </div>
        <div v-if="canInteractWithOrder" class="d-flex gap-4 align-items-center">
          <div class="d-flex gap-2 align-items-center">
            <el-tooltip
              v-if="orderSummaryButtonDisabled.disabled"
              popper-class="continue-tooltip"
              placement="top"
              :content="$t(`orders.${orderSummaryButtonDisabled.reason}`)"
            >
              <Button type="primary" :disabled="true" @click="toggleOrderSummaryModal">
                {{ $t('orders.goToOrderSummary') }}
                <ChevronIcon :size="20" :direction="$direction === 'rtl' ? 'left' : 'right'" />
              </Button>
            </el-tooltip>
            <Button v-else type="primary" @click="toggleOrderSummaryModal">
              {{ $t('orders.goToOrderSummary') }}
              <ChevronIcon :size="20" :direction="$direction === 'rtl' ? 'left' : 'right'" />
            </Button>
            <el-dropdown
              class="d-flex justify-content-end ms-1"
              trigger="click"
              :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
              @command="handleAction"
            >
              <Button type="icon" class="order-action-kebab" @click.stop>
                <KebabIcon />
              </Button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="ORDER_ACTIONS.RESET" :disabled="productsInOpenOrder.length === 0">
                  <div class="order-action" :class="productsInOpenOrder.length === 0 ? 'disabled-action' : ''">
                    <ResetNewIcon />
                    <p>{{ $t('orders.resetOrder') }}</p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :command="ORDER_ACTIONS.DELETE">
                  <div class="order-action delete-action">
                    <TrashCanIcon />
                    <p>{{ $t('orders.table.deleteActiveOrder.title') }}</p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </template>

    <CatalogModal
      v-if="showCatalogModal"
      v-loading="ordersLoading"
      :order-id="orderId"
      :display="showCatalogModal"
      :filtered-products="productsFilteredBySearch"
      :selected-products="productsInOpenOrder"
      :last-order-products="lastOrderProducts"
      :items-with-quantity-to-add-catalog="itemsWithQuantityToAddCatalog"
      :recommended-products="recommendedProducts"
      @on-close="toggleCatalogModal"
      @on-submit="submitCatalogChanges"
      @on-quantity-save-update="saveCatalogState"
      @on-add-item-to-cart="(product) => saveCatalogState(product, true)"
      @on-remove-item="removeFromCatalogState"
    />

    <StocktakingModal
      v-if="isStocktakingModalOpen"
      :supplier-name="supplierName"
      :mapped-products="productsFilteredBySearch"
      :order-local-storage="localStorageState"
      @on-close="isStocktakingModalOpen = false"
      @on-change="onStocktakingChange"
      @on-submit="submitStocktaking"
    />

    <div class="order-cart-body">
      <OrderInfo
        v-if="canInteractWithOrder"
        :arrives-at="arrivesAt"
        :closest-cut-off-date="closestCutOffDate"
        :closest-arrival-date="closestArrivalDate"
        :supplier-order-terms="supplierOrderTerms"
        :minimum-order-amount="minimumOrderAmount"
        :updated-at="updatedAt"
        :order-id="orderId"
        :opened-by-user="openedByUser"
        :opened-at="openedAt"
        @on-arrives-at-updated="onArrivesAtUpdated"
      />

      <div class="d-flex flex-column gap-2">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h3 v-if="productsInOpenOrder.length">
            {{ $t('orders.ProductsInCart', { productsLength: productsInOpenOrder.length }) }}
          </h3>
          <div v-if="productsInOpenOrder.length > 0 && !ordersLoading && canInteractWithOrder">
            <Button class="catalog-button" type="secondary" @click="toggleCatalogModal">
              <CatalogIcon :size="16" />
              {{ $t('orders.catalog') }}
            </Button>
          </div>
        </div>
        <TableLoadingSkeleton v-if="ordersLoading" />
        <template v-if="productsInOpenOrder.length > 0 || ordersLoading">
          <OrderProcessCartItems
            v-if="areOrderProcessCartItemsVisible && !ordersLoading"
            :products-list="productsInOpenOrder"
            :can-interact-with-order="canInteractWithOrder"
            @on-item-removal="removeItem"
            @on-quantity-change="updateItemQuantityState"
            @on-quantity-save-update="saveUpdatedQuantity"
          />
        </template>
        <EmptyCart
          v-else
          :loading="purchaseSummaryLoading"
          :is-stocktaking="isStocktaking"
          :can-add-recommended-products="canAddRecommendedProducts"
          :patch-order-process-items-loading="patchOrderProcessItemsLoading"
          @on-start-stocktaking="startStocktaking"
          @on-show-catalog="startCatalog"
          @on-reset-order="resetOrder"
        />
      </div>
    </div>
    <CartActionModals
      :supplier-name="supplierName"
      :is-reset-modal-open="isResetModalOpen"
      :is-add-item-to-cart-modal-open="isAddItemToCartModalOpen"
      :is-delete-modal-open="isDeleteModalOpen"
      :is-delete-all-items-modal-open="isDeleteAllItemsModalOpen"
      :is-stocktaking="isStocktaking"
      :item-to-save-in-cart-name="itemToSaveInCart.name"
      :delete-order-process-loading="deleteOrderProcessLoading"
      :delete-all-order-items-loading="deleteAllOrderItemsLoading"
      :products-in-open-order-that-arent-available="productsInOpenOrderThatArentAvailable"
      :is-delete-all-unavailable-items-modal-open="isDeleteAllUnavailableItemsModalOpen"
      :deleting-unavailable-items="deletingUnavailableItems"
      @on-reset-order="resetOrder"
      @on-delete-order="deleteOrder"
      @on-add-item-to-cart="addItemToCart"
      @on-delete-all-items="deleteItemsInOrder"
      @on-close-add-item-to-cart="isAddItemToCartModalOpen = false"
      @on-close-reset-order="isResetModalOpen = false"
      @on-close-delete-order="isDeleteModalOpen = false"
      @on-close-delete-all-items-in-order="isDeleteAllItemsModalOpen = false"
      @on-close-delete-all-unavailable-items="onCancelDeleteAllUnavailableItems"
      @on-delete-all-unavailable-items="submitDeleteProductsInOpenOrder"
    />
    <OrderSummaryModal
      v-if="showOrderConfirmation"
      :order-id="orderId"
      :order-number="orderNumber"
      :products="productsInOpenOrder"
      :supplier-order-terms="supplierOrderTerms"
      :supplier-id="supplierId"
      :closest-arrival-date="closestArrivalDate"
      :existing-order-note="existingOrderNote"
      :arrives-at="arrivesAt"
      :updated-at="updatedAt"
      @on-close="toggleOrderSummaryModal"
      @on-sent-order="onSentOrder"
      @on-arrives-at-updated="onArrivesAtUpdated"
    />
  </PageLayout>
</template>
<script>
import { ref, watch, computed, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { DateTime } from 'luxon';
import { clone, omit } from 'ramda';

import { ChevronIcon, KebabIcon, TrashCanIcon, ResetNewIcon, CatalogIcon } from '@/assets/icons';
import { PageLayout, TableLoadingSkeleton, Button, DIALOG_TYPES } from '@/modules/core';
import { useCurrency } from '@/locale/useCurrency';
import { useTenancy, useUser } from '@/modules/auth';

import OrderInfo from './components/OrderInfo.vue';
import { useExportProductsForOrderProcessCart } from '@/modules/purchase-management/compositions/overview/useSuppliersPurchaseManagement';
import {
  formatCurrency,
  formatFloat2Digits,
  formatDateWith2DigitsYear,
  formatDayAndDate,
} from '@/modules/purchase-management/purchaseManagementFormatters';
import {
  getClosestOrderTermCutOffDate,
  getClosestDeliveryArrivalDate,
  getMinimumOrderAmount,
} from '@/modules/purchase-management/purchaseManagement';
import { useSupplierOrderTerms } from '@/modules/purchase-management/components/orderTerms/compositions/useOrderTerms';

import OrderProcessCartItems from './components/OrderProcessCartItems';
import CatalogModal from './components/CatalogModal';
import StocktakingModal from './components/StocktakingModal';
import OrderSummaryModal from './components/OrderSummaryModal.vue';
import EmptyCart from './components/EmptyCart.vue';
import CartActionModals from './components/CartActionModals.vue';

import { useDeleteOrderItem } from './compositions/useDeleteOrderItem';
import { useDeleteAllOrderItems } from './compositions/useDeleteAllOrderItems';
import { useUpdateOrderItem } from './compositions/useUpdateOrderItem';
import { useProcessCartData } from './compositions/useGetProcessCartData';
import { useOrderLocalStorage } from './compositions/useOrderLocalStorage';
import { usePatchOrderProcessItems } from './compositions/usePatchOrderItems';
import { useSupplierRestockProductsList } from './compositions/useSupplierRestockProductsList';
import { useDeleteOrderProcess } from '@/modules/orderProcess/compositions/useDeleteOrderProcess';
import { useAnalytics } from '../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

const PERCENTAGE_TO_ALERT = 0.8;
const DISABLED_SUMMARY_STATES = {
  MISSING_QUANTITY: 'missingQuantity',
  QUANTITY_NOT_SUBMITTED: 'quantityNotSubmitted',
  NO_PRODUCTS: 'noProducts',
  UNAVAILABLE_PRODUCTS: 'unavailableProducts',
};
const PATCH_ITEMS_OPTIONS = {
  CATALOG: 'catalog',
  STOCKTAKING: 'stocktaking',
};
const ORDER_ACTIONS = {
  DELETE: 'delete',
  RESET: 'reset',
};

export default {
  components: {
    Button,
    PageLayout,
    KebabIcon,
    CatalogIcon,
    ResetNewIcon,
    TrashCanIcon,
    OrderProcessCartItems,
    ChevronIcon,
    CatalogModal,
    StocktakingModal,
    TableLoadingSkeleton,
    CartActionModals,
    OrderInfo,
    OrderSummaryModal,
    EmptyCart,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const showCatalogModal = ref(false);
    const showOrderConfirmation = ref(false);
    const patchedItemsFrom = ref(null);
    const areOrderProcessCartItemsVisible = ref(true);
    const { orderId, tenantId } = root.$route.params;
    const productSearch = ref('');
    const isAddItemToCartModalOpen = ref(false);
    const isResetModalOpen = ref(false);
    const isDeleteModalOpen = ref(false);
    const isDeleteAllItemsModalOpen = ref(false);
    const isStocktakingModalOpen = ref(false);
    const itemToSaveInCart = ref({});
    const orderItemByProductIdMap = ref({});
    const closestCutOffDate = ref();
    const closestArrivalDate = ref();
    const minimumOrderAmount = ref();
    const supplierOrderTerms = ref([]);
    const itemsToCreateFromCatalog = ref([]);
    const itemsToUpdateFromCatalog = ref([]);
    const { token } = useUser();
    const dismissedDeleteUnavailableItems = ref(false);
    const isDeleteAllUnavailableItemsModalOpen = ref(false);
    const bulkDeleteUnavailableItems = ref({
      itemsDeleted: 0,
      itemsToDelete: 0,
    });
    const deletingUnavailableItems = ref(false);
    const { logEvent } = useAnalytics();
    const isOrderSent = ref(false);

    const toDate = new Date();
    const fromDate = new Date(new Date().setFullYear(toDate.getFullYear() - 1));

    const { currencyFormat } = useCurrency();
    const { currentTenant } = useTenancy();
    const {
      patchOrderProcessItems,
      onDone: patchOrderProcessItemsOnDone,
      loading: patchOrderProcessItemsLoading,
    } = usePatchOrderProcessItems();

    const { localStorageState, resetOrderLocalStorage } = useOrderLocalStorage(orderId);
    const {
      deleteOrderProcess,
      onDone: deleteOrderProcessOnDone,
      loading: deleteOrderProcessLoading,
    } = useDeleteOrderProcess();
    const {
      deleteAllOrderItems,
      onDone: deleteAllOrderItemsOnDone,
      loading: deleteAllOrderItemsLoading,
    } = useDeleteAllOrderItems();

    const isStocktaking = computed(
      () =>
        localStorageState.value?.stocktaking === true &&
        localStorageState.value?.items.find(({ quantity }) => quantity > 0) !== undefined
    );

    const findAssociatedIntegralItems = (orderItem) => {
      const lastOrderIntegralItems = [];
      const lastOrderAssociatedItems = [];

      const {
        maxDateOrderItem: { discount, price, netAmountPerUnit, items: integralItems },
      } = orderItem;

      if (price) {
        lastOrderIntegralItems.push({ key: 'basePrice', value: formatCurrency(price, currencyFormat.value) });
      }

      if (discount) {
        const discountAmountInMoney = (Number(price) * Number(discount)) / 100;
        lastOrderIntegralItems.push({
          key: 'discount',
          discountPercent: discount,
          value: formatCurrency(discountAmountInMoney * -1, currencyFormat.value),
        });
      }
      integralItems?.forEach((integralItem) => {
        const { product, netAmountPerUnit, type, price } = integralItem;

        if (type === 'associatedIntegral') {
          lastOrderIntegralItems.push({
            itemName: product.name,
            key: product.name,
            value: formatCurrency(netAmountPerUnit, currencyFormat.value),
          });
        } else if (product) {
          lastOrderAssociatedItems.push({
            itemName: product.name,
            key: product.name,
            value: formatCurrency(price, currencyFormat.value),
            price,
          });
        }
      });

      if (netAmountPerUnit) {
        lastOrderIntegralItems.push({
          key: 'totalPrice',
          value: formatCurrency(netAmountPerUnit, currencyFormat.value),
        });
      }

      if (lastOrderAssociatedItems.length) {
        const calculatedPrice = lastOrderAssociatedItems.reduce((acc, item) => acc + item.price, netAmountPerUnit);

        lastOrderAssociatedItems.push({
          key: 'totalAssociatedPrice',
          value: formatCurrency(calculatedPrice, currencyFormat.value),
        });
      }

      return { lastOrderIntegralItems, lastOrderAssociatedItems };
    };

    const findOrderItemForProduct = (item) => {
      const orderItem = orderItemByProductIdMap.value[item.productId] ||
        orderItemByProductIdMap.value[item.id] || { maxDateOrderItem: {} };

      const {
        maxDateOrderItem: { quantity, netAmountPerUnit, orderDate: lastOrderDate },
        numberOfOrders,
        quantity: totalQuantity,
      } = orderItem;

      const { lastOrderIntegralItems, lastOrderAssociatedItems } = findAssociatedIntegralItems(orderItem);
      const averageOrderQuantity = totalQuantity && numberOfOrders ? totalQuantity / numberOfOrders : undefined;

      const quantityToAlertOver =
        averageOrderQuantity >= 1 ? averageOrderQuantity * (1 + PERCENTAGE_TO_ALERT) : undefined;

      const quantityToAlertLess =
        averageOrderQuantity >= 1 ? averageOrderQuantity * (1 - PERCENTAGE_TO_ALERT) : undefined;

      return {
        ...item,
        lastOrderPrice: formatCurrency(netAmountPerUnit, currencyFormat.value),
        lastOrderQuantity: quantity,
        value: item.name,
        total:
          netAmountPerUnit && item.quantity
            ? formatCurrency(netAmountPerUnit * item.quantity, currencyFormat.value)
            : undefined,
        averageOrderQuantity:
          totalQuantity && numberOfOrders ? formatFloat2Digits(totalQuantity / numberOfOrders) : undefined,
        lastOrderIntegralItems,
        lastOrderAssociatedItems,
        lastOrderDate: lastOrderDate ? formatDateWith2DigitsYear(lastOrderDate) : null,
        quantityToAlertOver,
        quantityToAlertLess,
        numberOfOrders,
      };
    };

    const {
      ordersLoading,
      updatedAt,
      supplierId,
      supplierName,
      arrivesAt,
      openedByUser,
      openedAt,
      orderNumber,
      productsLoading,
      productsInOpenOrder,
      lastOrderProducts,
      productsFilteredBySearch,
      existingOrderNote,
      canInteractWithOrder,
      addProductItem,
      removeProductItem,
      refetchOrderProcess,
      changeProductItemQuantity,
      refreshLocalOrderProcessState,
      productsInOpenOrderThatArentAvailable,
      updateItemQuantityState,
    } = useProcessCartData({ orderId, tenantId, productSearch, findOrderItemForProduct });
    const { loading: purchaseSummaryLoading, onResult } = useExportProductsForOrderProcessCart(
      ref({
        tenantId,
        supplierId,
        toDate: DateTime.fromJSDate(toDate).toISODate(),
        fromDate: DateTime.fromJSDate(fromDate).toISODate(),
      })
    );

    watch(
      [productsInOpenOrderThatArentAvailable, dismissedDeleteUnavailableItems],
      () => {
        if (productsInOpenOrderThatArentAvailable.value.length > 0 && !dismissedDeleteUnavailableItems.value) {
          isDeleteAllUnavailableItemsModalOpen.value = true;
        }
      },
      { immediate: true, deep: true }
    );

    const { deleteOrderItem, onDone: deletedOrderItemDone } = useDeleteOrderItem(updatedAt);
    const { updateOrderItem, onDone: updatedOrderItemDone } = useUpdateOrderItem(updatedAt);

    const recommendedProducts = computed(() => {
      const recommendedProductsToReturn = [];

      productsFilteredBySearch.value.forEach((product) => {
        if (product.isRecommended === true) {
          recommendedProductsToReturn.push({
            productId: product.id,
            name: product.name,
            sku: product.sku,
            quantity: undefined,
          });
        }
      });

      if (restockProductsList.value) {
        const productsIdsList = restockProductsList.value.products.map(({ productId }) => productId);
        return recommendedProductsToReturn
          .sort((a, b) => {
            return productsIdsList.indexOf(a.productId) - productsIdsList.indexOf(b.productId);
          })
          .map((recommendedProduct) => {
            const product = restockProductsList.value.products.find(
              ({ productId }) => productId === recommendedProduct.productId
            );
            if (product) return { ...recommendedProduct, maxStockLevel: product.maxStockLevel };

            return recommendedProduct;
          });
      }

      return recommendedProductsToReturn;
    });

    const { loading: supplierOrderTermsLoading, onResult: onSupplierOrderTermResult } = useSupplierOrderTerms(
      computed(() => ({
        supplierId,
        businessId: currentTenant.value.id,
        first: 25,
        after: 0,
      })),
      computed(() => !!supplierId.value)
    );

    onResult((data) => {
      const { productPurchaseSummary } = data?.data;
      orderItemByProductIdMap.value = productPurchaseSummary?.nodes.reduce((orderItemsByProduct, orderItem) => {
        if (orderItem.supplierId === supplierId.value) {
          if (!orderItemsByProduct[orderItem.product.id]) {
            orderItemsByProduct[orderItem.product.id] = orderItem;
          }
        }
        return orderItemsByProduct;
      }, {});

      lastOrderProducts.value.forEach((lastOrderProduct) => {
        const { productId } = lastOrderProduct;
        const productInAggregation = orderItemByProductIdMap.value[productId];

        if (productInAggregation) {
          lastOrderProduct.isRecommended = productInAggregation.isRecommended;
        }
      });
    });

    onSupplierOrderTermResult(
      ({
        data: {
          orderTerms: { nodes },
        },
      }) => {
        supplierOrderTerms.value = nodes;
        closestCutOffDate.value = getClosestOrderTermCutOffDate(supplierId.value, nodes);
        closestArrivalDate.value = getClosestDeliveryArrivalDate(supplierId.value, nodes);
        minimumOrderAmount.value = getMinimumOrderAmount(supplierId.value, nodes);
      }
    );

    const getMaxLevelForProduct = (rowData, restockList) => {
      if (restockList) {
        const product = restockList.products.find(({ productId }) => productId === rowData.productId);
        if (product) return { ...rowData, maxStockLevel: product.maxStockLevel };
      }
      return rowData;
    };

    const itemsWithQuantityToAddCatalog = computed(() => {
      const mutatedList = [];
      itemsToCreateFromCatalog.value.forEach(({ item }) => {
        mutatedList.push(item);
      });

      itemsToUpdateFromCatalog.value.forEach((item) => {
        mutatedList.push(item);
      });

      return mutatedList;
    });

    const mutatedProductsForCart = computed(() =>
      productsInOpenOrder.value.map((rowData) => ({
        ...rowData,
        ...getMaxLevelForProduct(rowData, restockProductsList.value),
      }))
    );

    patchOrderProcessItemsOnDone(({ data: { patchOrderProcessItemsNew: patchedOrderItems } }) => {
      patchedOrderItems.items.forEach((createdOrderItem) => {
        const alreadyExists = productsInOpenOrder.value.find(({ id }) => id === createdOrderItem.id);
        const clonedOrderItem = clone(createdOrderItem);
        if (!alreadyExists) {
          addProductItem(clonedOrderItem);
          disableItemInSearchList(clonedOrderItem);
        } else {
          changeProductItemQuantity(clonedOrderItem);
        }
      });
      if (patchedItemsFrom.value === PATCH_ITEMS_OPTIONS.CATALOG) {
        root.$message.success(root.$t('orders.successAddedFromCatalog'));
      } else {
        root.$message.success(root.$t('orders.emptyCart.success'));
      }
      patchedItemsFrom.value = null;
      updateUpdatedAt(patchedOrderItems);

      refetchOrderProcess();
    });

    updatedOrderItemDone(({ data: { updateOrderProcessItem: updatedOrderItem } }) => {
      updateUpdatedAt(updatedOrderItem);
    });

    deletedOrderItemDone(({ data: { deleteOrderProcessItem: deleteOrderItem } }) => {
      if (
        deletingUnavailableItems.value &&
        bulkDeleteUnavailableItems.value.itemsToDelete === bulkDeleteUnavailableItems.value.itemsDeleted
      ) {
        isDeleteAllUnavailableItemsModalOpen.value = false;
        deletingUnavailableItems.value = false;
        dismissedDeleteUnavailableItems.value = true;

        root.$message.success(
          root.$t('orders.deleteUnavailableItems.success', {
            count: bulkDeleteUnavailableItems.value.itemsToDelete + 1,
          })
        );
        bulkDeleteUnavailableItems.value = {
          itemsToDelete: 0,
          itemsDeleted: 0,
        };
      } else if (deletingUnavailableItems.value) {
        bulkDeleteUnavailableItems.value = {
          ...bulkDeleteUnavailableItems.value,
          itemsDeleted: bulkDeleteUnavailableItems.value.itemsDeleted + 1,
        };
      }

      updateUpdatedAt(deleteOrderItem);
    });

    const updateUpdatedAt = (result) => {
      if (result.updatedAt) {
        updatedAt.value = result.updatedAt;
      }
    };

    const orderSummaryButtonDisabled = computed(() => {
      const unavailableProductsExist = productsInOpenOrderThatArentAvailable.value.length > 0;
      const doesNotHaveProducts = !mutatedProductsForCart.value || mutatedProductsForCart.value.length === 0;

      if (unavailableProductsExist) {
        return { disabled: true, reason: DISABLED_SUMMARY_STATES.UNAVAILABLE_PRODUCTS };
      }

      if (doesNotHaveProducts) {
        return { disabled: true, reason: DISABLED_SUMMARY_STATES.NO_PRODUCTS };
      }

      const someProductsInCartDontHaveQuantity = mutatedProductsForCart.value.some(({ quantity }) => !quantity);
      if (someProductsInCartDontHaveQuantity) {
        return { disabled: true, reason: DISABLED_SUMMARY_STATES.MISSING_QUANTITY };
      }

      return { disabled: false };
    });

    const disableItemInSearchList = (item) => {
      const foundProduct = productsFilteredBySearch.value.find((product) => product.id === item.productId);
      if (foundProduct) foundProduct.disabled = true;
    };

    const enableItemInSearchList = (item) => {
      const foundProduct = productsFilteredBySearch.value.find((product) => product.id === item.productId);
      if (foundProduct) foundProduct.disabled = false;
    };

    const addItemToCart = (productItemFromCatalog = false) => {
      const product = productItemFromCatalog || itemToSaveInCart.value;
      isAddItemToCartModalOpen.value = false;

      addProductItem(product);
      disableItemInSearchList(product);
    };

    const { restockProductsList, loading: restockProductsListLoading } = useSupplierRestockProductsList({
      currentTenant,
      supplierId,
    });

    const removeItem = (itemToRemove) => {
      removeProductItem(itemToRemove);
      deleteOrderItem({ orderId, itemId: itemToRemove.id });
      enableItemInSearchList(itemToRemove);
    };

    const saveCatalogState = (item, create = false) => {
      if (create) {
        const itemIndexExistsInCreateList = itemsToCreateFromCatalog.value.findIndex(
          (itemIteration) => itemIteration?.item?.productId === item.productId
        );
        const itemToAppend = {
          orderId,
          item: {
            productId: item.productId || item.id,
            name: item.name,
            sku: item.sku,
            quantity: item.quantity,
          },
        };
        if (itemIndexExistsInCreateList === -1) {
          itemsToCreateFromCatalog.value = [...itemsToCreateFromCatalog.value, itemToAppend];
        } else {
          const listToUpdate = [...itemsToCreateFromCatalog.value];
          listToUpdate[itemIndexExistsInCreateList] = itemToAppend;
          itemsToCreateFromCatalog.value = listToUpdate;
        }
      } else {
        const itemIndexExistsInUpdateList = itemsToUpdateFromCatalog.value.findIndex(
          (itemIteration) => itemIteration?.itemId === item.id
        );
        const itemToAppend = {
          orderId,
          itemId: item.id,
          quantity: item.quantity,
        };
        if (itemIndexExistsInUpdateList === -1) {
          itemsToUpdateFromCatalog.value = [...itemsToUpdateFromCatalog.value, itemToAppend];
        } else {
          const listToUpdate = [...itemsToUpdateFromCatalog.value];
          listToUpdate[itemIndexExistsInUpdateList] = itemToAppend;
          itemsToUpdateFromCatalog.value = listToUpdate;
        }
      }
    };

    const removeFromCatalogState = ({ item, fromCreate = false }) => {
      if (fromCreate) {
        const itemIndexExistsInCreateList = itemsToCreateFromCatalog.value.findIndex(
          (itemIteration) => itemIteration?.item?.productId === item.productId
        );
        const listToUpdate = [...itemsToCreateFromCatalog.value];
        listToUpdate.splice(itemIndexExistsInCreateList, 1);
        itemsToCreateFromCatalog.value = listToUpdate;
      } else {
        const itemIndexExistsInUpdateList = itemsToUpdateFromCatalog.value.findIndex(
          (itemIteration) => itemIteration?.itemId === item.id
        );
        const listToUpdate = [...itemsToUpdateFromCatalog.value];
        listToUpdate.splice(itemIndexExistsInUpdateList, 1);
        itemsToUpdateFromCatalog.value = listToUpdate;
      }
    };

    const saveUpdatedQuantity = (changedItem) => {
      changeProductItemQuantity(changedItem);
      updateOrderItem({
        orderId,
        itemId: changedItem.id,
        quantity: changedItem.quantity,
        updatedAt: updatedAt.value,
      });
    };

    const toggleCatalogModal = () => {
      areOrderProcessCartItemsVisible.value = false;
      showCatalogModal.value = !showCatalogModal.value;
      refreshLocalOrderProcessState();
      areOrderProcessCartItemsVisible.value = true;
    };

    const toggleOrderSummaryModal = (refetch) => {
      if (showOrderConfirmation.value && refetch) {
        refetchOrderProcess();
      }
      if (!showOrderConfirmation.value) {
        logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.SUMMARY, { orderId });
      }
      showOrderConfirmation.value = !showOrderConfirmation.value;
    };

    const onSentOrder = () => {
      isOrderSent.value = true;
      toggleOrderSummaryModal();
      root.$router.push({ name: 'orders' });
    };

    const onDurationChange = () => {
      closestCutOffDate.value = getClosestOrderTermCutOffDate(supplierId.value, supplierOrderTerms.value);
      closestArrivalDate.value = getClosestDeliveryArrivalDate(supplierId.value, supplierOrderTerms.value);
    };

    const submitCatalogChanges = () => {
      let itemsToPatch = [];
      if (productsInOpenOrder.value.length > 0) {
        productsInOpenOrder.value.forEach((item) => {
          const itemToUpdateQuantity = itemsToUpdateFromCatalog.value.find(({ itemId }) => itemId === item.id);
          const itemQuantity = Number(item.quantity);

          const updatedQuantity = Number(
            Number(
              itemToUpdateQuantity ? Number(itemToUpdateQuantity.quantity) + itemQuantity : itemQuantity
            ).toLocaleString(root.$i18n.locale, { maximumFractionDigits: 3 })
          );

          itemsToPatch.push({
            id: item.id,
            productId: item.productId,
            name: item.name,
            sku: item.sku,
            quantity: updatedQuantity,
          });
        });
      }

      itemsToPatch.push(...itemsToCreateFromCatalog.value.map(({ item }) => item));

      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.UPDATE, {
        orderId,
        'ordered items': itemsToPatch,
        count: itemsToPatch.length,
        from: 'catalog',
      });
      patchOrderProcessItems({
        orderId,
        items: itemsToPatch,
        updatedAt: updatedAt.value,
      });
      patchedItemsFrom.value = PATCH_ITEMS_OPTIONS.CATALOG;
      toggleCatalogModal();
      itemsToCreateFromCatalog.value = [];
      itemsToUpdateFromCatalog.value = [];
    };

    const startStocktaking = () => {
      if (!localStorageState.value || !isStocktaking.value) {
        localStorageState.value = {
          stocktaking: true,
          items: recommendedProducts.value.map((product) => ({ ...product, quantity: undefined })),
        };
      }
      isStocktakingModalOpen.value = true;
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.METHOD, { orderId, 'order method': 'stocktaking' });
    };

    const startCatalog = () => {
      showCatalogModal.value = true;
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.METHOD, { orderId, 'order method': 'catalog' });
    };

    const onStocktakingChange = (updatedValue) => {
      localStorageState.value = updatedValue;
    };

    const submitStocktaking = () => {
      const items = localStorageState.value.items;
      const filteredDisabledItems = items.filter((item) => {
        const foundProduct = productsFilteredBySearch.value.find((product) => product.id === item.productId);
        return foundProduct?.available && !item.disabled;
      });
      let itemsToPatch = filteredDisabledItems;
      if (productsInOpenOrder.value.length > 0) {
        const productIdsToFilterOut = [];
        itemsToPatch = [];
        productsInOpenOrder.value.forEach((item) => {
          const itemFromStocktaking = filteredDisabledItems.find(
            (stocktakingItem) => stocktakingItem.productId === item.productId
          );
          if (itemFromStocktaking) productIdsToFilterOut.push(item.productId);

          const itemQuantity = Number(item.quantity);

          const updatedQuantity = Number(
            Number(
              itemFromStocktaking ? Number(itemFromStocktaking.quantity) + itemQuantity : itemQuantity
            ).toLocaleString(root.$i18n.locale, { maximumFractionDigits: 3 })
          );

          itemsToPatch.push({
            id: item.id,
            productId: item.productId,
            name: item.name,
            sku: item.sku,
            quantity: updatedQuantity,
          });
        });
        itemsToPatch.push(
          ...filteredDisabledItems.filter(({ productId }) => !productIdsToFilterOut.includes(productId))
        );
      }

      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.UPDATE, {
        orderId,
        'ordered items': itemsToPatch,
        count: itemsToPatch.length,
        from: 'stocktaking',
      });

      patchOrderProcessItems({
        orderId,
        items: itemsToPatch.map((item) => omit(['maxStockLevel', 'disabled'], item)),
        updatedAt: updatedAt.value,
      });

      patchedItemsFrom.value = PATCH_ITEMS_OPTIONS.CATALOG;
      resetOrderLocalStorage();
      isStocktakingModalOpen.value = false;
    };

    const resetOrder = () => {
      resetOrderLocalStorage();
      logResetOrderEvent();
      isResetModalOpen.value = false;
    };

    const logResetOrderEvent = (cartEmpty = true) => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.RESET, { orderId, 'cart empty': cartEmpty });
    };

    const handleAction = (action) => {
      if (action === ORDER_ACTIONS.DELETE) {
        isDeleteModalOpen.value = true;
      } else if (action === ORDER_ACTIONS.RESET) {
        isDeleteAllItemsModalOpen.value = true;
      }
    };

    const deleteItemsInOrder = () => {
      deleteAllOrderItems({ orderId, updatedAt: updatedAt.value });
    };

    deleteAllOrderItemsOnDone(({ data: { deleteAllOrderProcessItems } }) => {
      productsInOpenOrder.value.forEach((item) => {
        removeProductItem(item);
        enableItemInSearchList(item);
      });

      resetOrderLocalStorage();
      isDeleteAllItemsModalOpen.value = false;
      updateUpdatedAt(deleteAllOrderProcessItems);
      logResetOrderEvent(false);
      root.$message.success(root.$t('orders.deletedAllItemsInOrder'));
    });

    const deleteOrder = () => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.DELETE, {
        orderId: orderId,
        'cart empty': productsInOpenOrder.value.length === 0,
        from: 'cart',
      });
      deleteOrderProcess({ orderId });
    };

    deleteOrderProcessOnDone(() => {
      root.$router.push({ name: 'orders' });
      root.$message.success(root.$t('orders.table.deleteActiveOrder.success'));
    });

    const onArrivesAtUpdated = (patchResultWithArrivesAt) => {
      arrivesAt.value = patchResultWithArrivesAt.arrivesAt;
      updateUpdatedAt(patchResultWithArrivesAt);
      root.$message.success(root.$t('orders.arrivesAtUpdated'));
    };

    const submitDeleteProductsInOpenOrder = () => {
      deletingUnavailableItems.value = true;
      bulkDeleteUnavailableItems.value = {
        itemsToDelete: productsInOpenOrderThatArentAvailable.value.length - 1,
        itemsDeleted: 0,
      };

      for (const product of productsInOpenOrderThatArentAvailable.value) {
        removeItem(product);
      }
    };

    const onCancelDeleteAllUnavailableItems = () => {
      isDeleteAllUnavailableItemsModalOpen.value = false;
      dismissedDeleteUnavailableItems.value = true;
    };

    const logOrderExited = () => {
      if (!isOrderSent.value) {
        logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.EXIT, { orderId });
      }
    };

    onMounted(() => {
      window.addEventListener('beforeunload', logOrderExited);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', logOrderExited);
      logOrderExited();
    });

    return {
      DIALOG_TYPES,
      ORDER_ACTIONS,
      orderId,
      arrivesAt,
      updatedAt,
      deleteOrder,
      orderNumber,
      isStocktaking,
      localStorageState,
      isDeleteModalOpen,
      submitCatalogChanges,
      existingOrderNote,
      closestCutOffDate,
      closestArrivalDate,
      isStocktakingModalOpen,
      isDeleteAllItemsModalOpen,
      supplierOrderTermsLoading,
      supplierOrderTerms,
      supplierName: supplierName || '',
      supplierId,
      handleAction,
      currencyFormat,
      ordersLoading,
      currentTenant,
      minimumOrderAmount,
      isResetModalOpen,
      canInteractWithOrder,
      productsLoading: computed(() => ordersLoading.value || productsLoading.value),
      purchaseSummaryLoading: computed(
        () =>
          ordersLoading.value ||
          productsLoading.value ||
          purchaseSummaryLoading.value ||
          restockProductsListLoading.value
      ),
      canAddRecommendedProducts: computed(() => recommendedProducts.value.length > 0),
      orderSummaryButtonDisabled,
      showOrderConfirmation,
      productsFilteredBySearch,
      productSearch,
      productsInOpenOrder: mutatedProductsForCart,
      startCatalog,
      startStocktaking,
      toggleOrderSummaryModal,
      removeItem,
      onSentOrder,
      showCatalogModal,
      lastOrderProducts,
      onDurationChange,
      saveUpdatedQuantity,
      toggleCatalogModal,
      recommendedProducts,
      formatDayAndDate,
      patchOrderProcessItemsLoading,
      areOrderProcessCartItemsVisible,
      itemsWithQuantityToAddCatalog,
      updateItemQuantityState,
      saveCatalogState,
      removeFromCatalogState,
      onStocktakingChange,
      isAddItemToCartModalOpen,
      addItemToCart,
      deleteItemsInOrder,
      itemToSaveInCart,
      submitStocktaking,
      openedAt,
      openedByUser,
      token,
      deleteOrderProcessLoading,
      deleteAllOrderItemsLoading,
      deletingUnavailableItems,
      submitDeleteProductsInOpenOrder,
      onCancelDeleteAllUnavailableItems,
      resetOrder,
      onArrivesAtUpdated,
      isDeleteAllUnavailableItemsModalOpen,
      productsInOpenOrderThatArentAvailable,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.order-action-kebab {
  height: 2.5rem;
  width: 2.5rem;
}
.order-action {
  color: $typography-primary;
  display: flex;
  height: 2rem;
  align-items: center;
  gap: 0.5rem;
}
.delete-action {
  color: $checkbox-error;
}
.disabled-action {
  color: #c4c6cf;
}
</style>
<style lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .el-dialog {
  width: 100%;
  max-width: 1216px;
  margin-top: 5vh !important;
}
.empty-cart-description {
  color: $typography-secondary;
  text-align: center;
}
.order-cart-body {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.stocktaking-btn {
  height: 2.375rem;
}
.catalog-button {
  height: 2rem;
}
.continue-tooltip {
  &.el-tooltip__popper {
    max-width: 14rem;
    padding: 5px;
  }
}
.supplier-name {
  font-weight: 500;
}
</style>
